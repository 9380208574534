import React, { useMemo } from "react";
import cx from "classnames";
import range from "lodash/range";
import Head from "next/head";
import Link from "next/link";
import { NextRouter, useRouter } from "next/router";

import { Action, Category, Label } from "constants/events";
import useDomain from "hooks/useDomain";
import { useEventTracker } from "hooks/useEventTracker";

import ChevronIcon from "components/Icons/ChevronIcon";

type Props = {
  className?: string;
  currentPage: number;
  onClickTrackEvent?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => void;
  onNext?: () => void;
  /* Custom onClick for page numbers select when it deviates
   * from ?page=3 query param structure.
   */
  onPageSelect?: (page: number) => void;
  onPrev?: () => void;
  showRelativeLinkTags?: boolean;
  totalPages: number;
  category?: string;
};

const intercalate = (delim: (string | number)[], arrs: (string | number)[][]) =>
  arrs.reduce((acc, curr) => acc.concat(delim, curr));

const PAGE_BREAKPOINT = 5;
const ELLIPSE = "...";

const PageNavigation: React.FC<Props> = ({
  className,
  currentPage,
  onClickTrackEvent,
  onNext,
  onPageSelect,
  onPrev,
  showRelativeLinkTags = true,
  totalPages,
  category,
}) => {
  const { asPath, push } = useRouter();
  const router = useRouter();
  const domain = useDomain();
  const { publishEvent } = useEventTracker();
  const path = `${domain}${asPath.split("?")[0]}`;
  const prevUrl =
    currentPage - 1 === 1 ? path : `${path}?page=${currentPage - 1}`;
  const nextUrl = `${path}?page=${currentPage + 1}`;
  const { page: _queryPage, ...queryWithoutPage } = router.query;

  const currentPagination = useMemo(() => {
    const totalPagesInt = Number(totalPages);
    const constructPagination = (segments: (string | number)[][]) => {
      const intercalatedSegments = intercalate([ELLIPSE], segments);
      return intercalatedSegments.map((page) => ({
        page,
        path: `${path}${page}`,
      }));
    };

    // Total Pages LTE 5
    // no ellipses < 1 2 [3] 4 >
    if (totalPagesInt <= PAGE_BREAKPOINT) {
      return constructPagination([range(1, totalPagesInt + 1)]);
    }

    // Current page LT 5 and total pages GT 5
    // show ellipse only before last page < 1 [2] 3 ... 20 >
    if (totalPagesInt > PAGE_BREAKPOINT && currentPage < PAGE_BREAKPOINT) {
      const windowEnd = Math.max(currentPage + 2, 4);
      return constructPagination([range(1, windowEnd), [totalPagesInt]]);
    }

    // Current page GTE 5 and more than 3 less than total
    // show ellipse on both sides with a window of 3 positions < 1 ... 6 [7] 8 ... 20 >
    if (currentPage >= PAGE_BREAKPOINT && currentPage < totalPagesInt - 3) {
      return constructPagination([
        [1],
        range(currentPage - 1, currentPage + 2),
        [totalPagesInt],
      ]);
    }

    // Current page 3 or less positions away from end
    // show ellipse between 1 and tail of list < 1 ... 16 [17] 18 19 20 >
    const start = Math.min(totalPagesInt - 2, currentPage - 1);
    return constructPagination([[1], range(start, totalPagesInt + 1)]);
  }, [currentPage, totalPages]);

  if (totalPages < 2) {
    return <></>;
  }

  const onPageClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    page: string | number,
  ) => {
    const { page: _queryPage, ...queryWithoutPage } = router.query;

    e.preventDefault();
    publishEvent({
      action: Action.click,
      category: Category.pagination,
      label: `${Label.page}#${page}`,
    });
    if (onPageSelect) {
      return onPageSelect(page as number);
    }

    return push(
      {
        query: page > 1 ? { ...router.query, page: page } : queryWithoutPage,
      },
      undefined,
      { scroll: true, shallow: true },
    );
  };

  const onNextPage = async (page: number, router: NextRouter) => {
    router.push({ query: { ...router.query, page: page + 1 } }, undefined, {
      scroll: true,
      shallow: true,
    });
  };

  const onPrevPage = async (page: number, router: NextRouter) => {
    const { page: _queryPage, ...queryWithoutPage } = router.query;
    router.push(
      {
        query:
          page > 2 ? { ...router.query, page: page - 1 } : queryWithoutPage,
      },
      undefined,
      {
        scroll: true,
        shallow: true,
      },
    );
  };

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    onClick: () => void,
  ) => {
    e.preventDefault();
    onClickTrackEvent && onClickTrackEvent(e);
    onClick();
  };

  return (
    <>
      {showRelativeLinkTags && (
        <Head>
          {/* SEO: All pages that can possibly be paginated should have these tags*/}
          {currentPage > 1 && <link rel="prev" href={prevUrl} />}
          {currentPage < totalPages && <link rel="next" href={nextUrl} />}
        </Head>
      )}

      <nav
        aria-label="Navigate between pages"
        className={cx(className, "flex items-center justify-center")}
      >
        <div className="flex gap-2">
          {currentPage !== 1 && (
            <Link
              className="self-center p-2"
              href={{
                query:
                  currentPage > 2 || category === "news"
                    ? { ...router.query, page: currentPage - 1 }
                    : queryWithoutPage,
              }}
              onClick={(e) =>
                handleClick(
                  e,
                  onPrev || (() => onPrevPage(currentPage, router)),
                )
              }
              aria-label="Previous Page"
            >
              <ChevronIcon
                className="inline-block text-green"
                direction="left"
              />
            </Link>
          )}
          <div
            className="flex flex-grow items-center justify-center text-center"
            data-testid="pages-shown"
          >
            {currentPagination.map(({ page, path }) => {
              return page === ELLIPSE ? (
                <span
                  key={path}
                  className="rounded-sm w-10 h-10 flex items-center justify-center text-baseline no-underline"
                >
                  {page}
                </span>
              ) : (
                <Link
                  href={{
                    query:
                      page > 1 || category === "news"
                        ? { ...router.query, page: page }
                        : queryWithoutPage,
                  }}
                  key={path}
                  className={cx(
                    "rounded-sm w-10 h-10 flex items-center justify-center",
                    {
                      "bg-light-grey": page === currentPage,
                      "underline text-green": page !== currentPage,
                    },
                  )}
                  onClick={(e) => onPageClick(e, page)}
                >
                  {page}
                </Link>
              );
            })}
          </div>
          {currentPage !== Number(totalPages) && (
            <Link
              className="self-center justify-end p-2"
              href={{
                query: { ...router.query, page: currentPage + 1 },
              }}
              onClick={(e) =>
                handleClick(
                  e,
                  onNext || (() => onNextPage(currentPage, router)),
                )
              }
              aria-label="Next Page"
            >
              <ChevronIcon
                className="inline-block text-green"
                direction="right"
              />
            </Link>
          )}
        </div>
      </nav>
    </>
  );
};

export default PageNavigation;
