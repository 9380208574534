import { useContext, useEffect, useState } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";
import { trackEvent } from "@leafly-com/web-utils";
import classnames from "classnames";

import { Action } from "constants/events";
import ConfigContext from "context/ConfigContext";
import FinderResultsContext from "context/FinderResultsContext";
import MapContext from "context/MapContext";
import { hasAnyDelivery } from "utils/dispensaryUtils";
import { getMerchId } from "utils/eventTracking";
import { getSplitKey } from "utils/split/getSplitKey";
import { getStoreUrl } from "utils/storeUrlUtils";
import { getTierName } from "utils/tiers";

import TrackImpression from "components/TrackImpression";

import DynamicMarker from "./DynamicMarker";
import MapMarkerImage from "./MapMarkerImage";

const markerLayers = {
  basic: 200,
  custom: 500,
  info: 100,
  pro: 400,
  standard: 300,
};

const getZIndex = (
  tierLevel: number,
  tierName: keyof typeof markerLayers,
  isPremium: boolean,
  isSelected: boolean,
  isHovered?: boolean,
) => {
  let zIndex = 0;
  switch (true) {
    case isSelected || isHovered:
      zIndex = 1005;
      break;
    case tierLevel < 5 && isPremium:
      zIndex = 1005 - tierLevel;
      break;
    case !!tierName:
      zIndex = Math.floor(Math.random() * 100) + markerLayers[tierName];
      break;
    default:
      break;
  }

  if (typeof zIndex === "number") {
    return zIndex;
  }

  return 0;
};

type PlatinumImpressionWrapperProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  children: any;
  isPlatinum: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  mapMarker: any;
};

export const PlatinumImpressionWrapper = ({
  children,
  isPlatinum,
  mapMarker,
}: PlatinumImpressionWrapperProps) => {
  if (isPlatinum) {
    const merchId = getMerchId(
      mapMarker,
      isPlatinum ? "platinumplacement" : "custommapicon",
    );
    return (
      <TrackImpression
        category="Finder VNow"
        label="Platinum Placement - Pin"
        customDimensions={{ merchandisingCampaignId: merchId }}
      >
        {children}
      </TrackImpression>
    );
  } else {
    return <>{children}</>;
  }
};

type MapMarkerProps = {
  lat: number; // used by google-react-map for positioning
  lng: number; // used by google-react-map for positioning
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  mapMarker: any;
  isSelected?: boolean;
  isHovered?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  setSelectedStore: (arg: any) => any;
};

const MapMarker = ({
  mapMarker,
  isSelected,
  isHovered,
  setSelectedStore,
}: MapMarkerProps) => {
  const { mapMarkerSize, name, slug } = mapMarker;
  const tierName = getTierName(mapMarker);
  const isPlatinum =
    mapMarkerSize === "xl" || tierName.toLowerCase() === "platinum";
  const isDeliveryMapMarkerOnly = hasAnyDelivery(mapMarker);

  const { retailType, storeRoute } = useContext(ConfigContext);

  const { handleMouseOverStore, handleMouseAwayStore } = useContext(MapContext);

  const { strainFilters, availableFilters } = useContext(FinderResultsContext);

  const [zIndex, setZIndex] = useState(
    getZIndex(
      mapMarker.tier,
      tierName as keyof typeof markerLayers,
      isPlatinum,
      !!isSelected,
    ),
  );

  const storeUrl = getStoreUrl({
    availableFilters,
    storeRoute,
    storeSlug: slug,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    strainFilters: strainFilters as any,
  });
  const handleClick = () => {
    if (isSelected) {
      if (["custom", "platinum"].includes(tierName)) {
        const category = `map marker - ${isPlatinum ? "platinum" : "custom"}`;
        const merchId = getMerchId(
          mapMarker,
          isPlatinum ? "platinumplacement" : "custommapicon",
        );
        trackEvent(category, Action.click, mapMarker.name, {
          dispensaryId: mapMarker.id,
          isKeyMetric: true,
          merchandisingCampaignId: merchId,
        });
      }
      window.location.assign(storeUrl);
    } else {
      setSelectedStore(mapMarker);
      sendClientSideSplitTrackEvent(
        {
          eventType: "webFinder_storeMapPinClick_frontend",
          properties: {
            retailType,
            tier: mapMarker.tier,
          },
        },
        getSplitKey(),
      );
    }
  };

  const classes = classnames(
    "flex absolute items-center justify-center transform -translate-x-2/4 bottom-0 h-auto finder-map-marker",
    {
      [`marker-size--${mapMarkerSize}`]: mapMarkerSize,
      "hovered-store-animation": isHovered,
    },
  );

  useEffect(() => {
    setZIndex(
      getZIndex(
        mapMarker.tier,
        tierName as keyof typeof markerLayers,
        isPlatinum,
        !!isSelected,
        isHovered,
      ),
    );
  }, [isPlatinum, isSelected, isHovered]);

  return (
    <>
      <button
        className={classes}
        style={{
          zIndex,
        }}
        onClick={handleClick}
        onMouseEnter={() => handleMouseOverStore(mapMarker.id)}
        onMouseLeave={handleMouseAwayStore}
        data-testid={"redesign-map-marker"}
        title={`click to highlight ${mapMarker.name}`}
      >
        <MapMarkerImage
          logoImage={mapMarker?.logoImage || ""}
          alt={name}
          tierName={tierName}
          isPlatinum={isPlatinum}
          isDeliveryOnly={isDeliveryMapMarkerOnly}
        />
        <DynamicMarker tier={tierName} isHovered={isHovered} />
      </button>
      <style jsx global>{`
        .finder-map-marker {
          --finder-map-marker-xl: 72px;
          --finder-map-marker-lg: 50px;
          --finder-map-marker-md: 32px;
          --finder-map-marker-sm: 12px;
          --finder-map-marker-hover-grow: 8px;
        }
        .finder-map-marker > * {
          pointer-events: none;
          touch-action: none;
        }
        .marker-size--xl {
          width: var(--finder-map-marker-xl);
        }
        .marker-size--xl .map-marker__image {
          width: calc(var(--finder-map-marker-xl) - 12px);
          height: calc(var(--finder-map-marker-xl) - 12px);
          top: 4px;
          left: 5px;
        }
        .marker-size--lg {
          width: var(--finder-map-marker-lg);
        }
        .marker-size--lg .map-marker__image {
          width: calc(var(--finder-map-marker-lg) - 6px);
          height: calc(var(--finder-map-marker-lg) - 6px);
        }
        .marker-size--md {
          width: var(--finder-map-marker-md);
        }
        .marker-size--sm {
          width: var(--finder-map-marker-sm);
        }
        .hovered-store-animation svg {
          overflow: visible !important;
        }
        .hovered-store-animation {
          transition:
            height 0.25s ease,
            width 0.25s ease;
        }
        .hovered-store-animation .map-marker__image {
          transition:
            height 0.25s ease,
            width 0.25s ease;
        }
        .hovered-store-animation.marker-size--xl .map-marker__image {
          width: calc(
            var(--finder-map-marker-xl) + var(--finder-map-marker-hover-grow) -
              12px
          );
          height: auto;
        }
        .hovered-store-animation.marker-size--lg .map-marker__image {
          width: calc(
            var(--finder-map-marker-lg) + var(--finder-map-marker-hover-grow) -
              6px
          );
          height: calc(
            var(--finder-map-marker-lg) + var(--finder-map-marker-hover-grow) -
              6px
          );
        }
        .hovered-store-animation.marker-size--xl {
          width: calc(
            var(--finder-map-marker-xl) + var(--finder-map-marker-hover-grow)
          );
          height: auto;
        }
        .hovered-store-animation.marker-size--lg {
          width: calc(
            var(--finder-map-marker-lg) + var(--finder-map-marker-hover-grow)
          );
          height: auto;
        }
        .hovered-store-animation.marker-size--md {
          width: calc(
            var(--finder-map-marker-md) + var(--finder-map-marker-hover-grow)
          );
          height: auto;
        }
        .hovered-store-animation.marker-size--sm {
          width: calc(
            var(--finder-map-marker-sm) + var(--finder-map-marker-hover-grow)
          );
          height: auto;
        }
      `}</style>
    </>
  );
};

export default MapMarker;
