import { forwardRef, useContext } from "react";

import FinderResultsContext from "context/FinderResultsContext";

import PageNavigation from "components/PageNavigation";

function listingsHeaderScrollIntoView() {
  const headerMessage = document.getElementById(
    "finder-listings__header-message",
  );
  if (headerMessage) {
    headerMessage.scrollIntoView();
  }
}

const PaginationWithData = forwardRef(() => {
  const { getPage, pageCount, handlePageChange } =
    useContext(FinderResultsContext);

  const handleSetPage = (value: number) => {
    listingsHeaderScrollIntoView();
    handlePageChange(value);
  };

  return (
    <PageNavigation
      currentPage={getPage()}
      totalPages={pageCount}
      onNext={() => {
        handleSetPage(getPage() + 1);
      }}
      onPrev={() => {
        handleSetPage(getPage() - 1);
      }}
      onPageSelect={(pg) => handleSetPage(pg)}
    />
  );
});

export default PaginationWithData;
