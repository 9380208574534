import { useCallback, useContext, useRef } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import debounce from "lodash/debounce";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import ConfigContext from "context/ConfigContext";
import FinderResultsContext from "context/FinderResultsContext";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import isCanada from "utils/isCanada";
import { localizeText } from "utils/localizeText";
import slugifyName from "utils/slugifyName";

import DispensaryCardPlaceholder from "components/botanic/DispensaryCard/DispensaryCardPlaceholder";
import Pagination from "components/Finder/Pagination";
import RecentlyViewedCarouselWithData from "components/Finder/RecentlyViewedCarousel";
import Sort from "components/Finder/Sort";
import Placeholder, { Rect } from "components/Placeholder";

import CBDStoresCarousel from "./CBDStoresCarousel";
import NoResults from "./NoResults";
import StoreCard from "./StoreCard";

const DoctorAd = dynamic(() => import("components/DoctorAd/DoctorAd"));

const FinderListings = () => {
  const topOfListings = useRef(null);

  const {
    sponsoredStores,
    stores,
    getPage,
    loadingStores,
    promotedNewStores,
    sortBy,
    storesCount,
    strainFilters,
    activeFilters,
  } = useContext(FinderResultsContext);

  const { pathname } = useRouter();
  const countryCode = useDomainCountryCode();
  const isCa = isCanada(countryCode);

  const showCBDTreatment =
    !pathname.includes("doctors") &&
    !pathname.includes("cbd-stores") &&
    !strainFilters.strain &&
    !isCa &&
    !activeFilters.includes("black_owned");

  const { retailType } = useContext(ConfigContext);
  const { query } = useRouter();
  const page: number = getPage(Number(query?.page) || 1);
  const scrollListener = () => {
    trackEvent("List", "Scroll List");
  };
  const hasNoDeliveryResults =
    stores.length === 0 && query?.filter?.includes("delivery");

  const keywords = (
    type: "dispensary" | "cbd-store" | "clinic",
    isPlural?: boolean,
  ) => {
    switch (type) {
      case "dispensary":
        return isPlural
          ? localizeText("Dispensaries", countryCode)
          : localizeText("Dispensary", countryCode);
      case "cbd-store":
        return isPlural ? "Hemp / CBD Stores" : "Hemp / CBD Store";
      case "clinic":
        return isPlural ? "Doctors" : "Doctor";
      default:
        return "";
    }
  };

  const lazyScrollListener = useCallback(debounce(scrollListener, 500), []);
  const shouldShowSponsored =
    sponsoredStores.length > 0 &&
    page === 1 &&
    (sortBy?.display === "Recommended" || sortBy?.param === "default");

  if (loadingStores) {
    const numberOfSkelements = Array(20).fill(0);

    return (
      <div className="w-full">
        <div className="flex flex-row items-center justify-between my-sm">
          <div className="text-xs ">Loading results</div>
          <Sort />
        </div>
        <div className="flex flex-row items-center justify-between">
          <Placeholder width="175px" height="18px" className="mt-lg mb-lg">
            <Rect width="100%" height="100%" />
          </Placeholder>
        </div>
        {numberOfSkelements.map((skele, idx) => (
          <div key={idx}>
            <DispensaryCardPlaceholder
              id={`dis-card-placeholder-${idx}`}
              className="mb-md"
              variant="platinum"
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="w-full" onScroll={lazyScrollListener} ref={topOfListings}>
      {!hasNoDeliveryResults && (
        <div className="flex flex-row items-center justify-between my-sm">
          <div className="text-xs ">{storesCount} results</div>
          <Sort />
        </div>
      )}
      {retailType === "clinic" && <DoctorAd />}

      {shouldShowSponsored && (
        <>
          <div className="flex flex-row justify-between">
            <h2 className="text-xs uppercase my-sm text-default">
              {`Sponsored ${keywords(retailType, true)}`}
            </h2>
          </div>
          <ul className="flex flex-col">
            {sponsoredStores.map((store, i) => {
              return (
                <StoreCard
                  key={store.id}
                  store={store}
                  index={i}
                  id={`${slugifyName(store.name)}-listing-card`}
                  compact={false}
                  isUnorderedList={true}
                />
              );
            })}
          </ul>
        </>
      )}
      <RecentlyViewedCarouselWithData />
      {storesCount === 0 && <NoResults />}
      {stores.length > 0 && (
        <>
          <div className="flex flex-row items-center justify-between">
            <h2 className="text-xs uppercase text-default mt-lg mb-sm">
              {`All ${keywords(retailType)} results`}
            </h2>
          </div>
          <ul className="flex flex-col">
            {promotedNewStores.concat(stores).map((store, i) => {
              return (
                <StoreCard
                  key={store.id}
                  store={store}
                  index={i}
                  id={`${slugifyName(store.name)}-listing-card`}
                  compact={false}
                  retailType={retailType}
                  isUnorderedList={true}
                />
              );
            })}
          </ul>
          <Pagination />
        </>
      )}
      {showCBDTreatment && storesCount <= 5 && <CBDStoresCarousel />}
    </div>
  );
};

export default FinderListings;
